<template>
  <v-container fluid class="px-0">
    <TermsAndConditionsPage />
  </v-container>
</template>

<script>
import TermsAndConditionsPage from "@/components/layout/others/TermsAndConditionsPage";
export default {
  name: "TermsAndConditions",
  components: { TermsAndConditionsPage }
};
</script>

<style scoped></style>
