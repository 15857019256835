<template>
  <v-card>
    <v-card-title>
      <div class="headline">Cross Point 利用規約</div>
    </v-card-title>
    <v-card-subtitle>
      <div class="subtitle-2 font-weight-bold">
        Last updated: {{ lastUpdateDate }}
      </div>
    </v-card-subtitle>
    <v-card-text>
      <p>
        利用規約 リディッシュ株式会社（以下「当社」といいます。）が提供するCross
        Pointは、以下の通り利用規約を定めます。Cross
        Pointを利用する登録ユーザー（第1条で定義します。）は、この利用規約を読み、その精神を尊重してCross
        Pointを利用するものとします。
      </p>

      <p class="subtitle-1 font-weight-bold main--text lighten-2">
        第一章：定義及び本規約について
      </p>
      <p>
        第1条（定義）<br />
        Cross
        Point利用規約で使用する用語は各々以下に定める意味を有するものとします。
        本サービス：当社が提供するCross Pointをいいます。<br />
        本アカウント：本サービスを利用するために必要なCross
        Pointアカウントをいいます。<br />
        登録ユーザー：Cross
        Pointアカウントを有する本サービスの利用者をいいます。<br />
        本規約：本サービスの利用に関する条件を、登録ユーザーと当社との間で定める「Cross
        Point利用規約」をいいます。 <br />
        登録情報：本アカウント取得時に、当社が指定して登録いただく、登録ユーザー自身に関する情報をいいます。<br />
        本ユーザー資格：登録ユーザーが、本サービス上で有する資格をいい、具体的には本サービス上で目標売上の設定等の各種機能を使用することができることをいいます。<br />
        外部サービス：freeeなど、当社がその利用を承認し、又は連携する認証システムを有するサービスの総称をいいます。
      </p>
      <p>
        第2条（適用範囲・利用規約の変更）<br />
        本規約は、登録ユーザーと当社の間における、本サービスの利用につき生じるすべての法律関係に適用されます。登録ユーザーは本規約に同意のうえ本サービスを利用するものとします。
      </p>
      <p>
        登録ユーザーが本アカウントの登録を行った場合、本規約に同意したものとみなします。
      </p>
      <p>
        当社は、次の各号のいずれかに該当する場合、登録ユーザーの承諾を得ることなく本規約の内容を変更（追加を含む）できるものとします。
      </p>
      <p>
        ・本規約の変更が、登録ユーザーの一般の利益に適合するとき。<br />
        ・本規約の変更が、登録ユーザーが本サービスの利用登録をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。
      </p>
      <p>
        当社は、本規約の変更を行う場合、変更した規約の効力発生日より1週間前までに、本規約を変更すること及び、変更後の規約の内容及び効力発生日を、公式ブログでお知らせ又は、登録されたメールアドレスへ送信する方法で通知します。
      </p>
      <p>
        変更後の規約の効力発生日以降に登録ユーザーが本サービスを利用した場合は、本規約の変更に同意したものとみなします。
      </p>

      <p class="subtitle-1 font-weight-bold main--text lighten-2">
        第二章：本サービスの利用について
      </p>
      <p>
        第3条（利用登録）<br />
        本サービスを登録ユーザーとして利用することを希望する者は、本サービスに利用登録のうえ本アカウントを取得するものとします
      </p>
      <p>登録ユーザーは1人につき唯一のアカウントを保有できるものとします。</p>
      <p>
        登録ユーザーは、会員情報の登録時及び登録後も、自らの会員情報を最新、完全、かつ正確に保つこととし、登録情報に変更が生じた場合、速やかに情報を更新するものとします。
      </p>
      <p>
        第4条（ユーザー名とパスワード）<br />
        登録ユーザーは、設定した自らのユーザー名とログインパスワードの管理を自己の責任で行います。
      </p>
      <p>
        ユーザー名及びログインパスワードを利用して行われた行為は、当該ユーザー名及びパスワードを有する登録ユーザーが行ったものとみなします。
      </p>
      <p>
        ユーザー名とログインパスワードを取得するために登録されたメールアドレス、その他の情報は、正確かつ以下に該当しないものでなければなりません。
      </p>
      <p>
        ・暴力的な表現、反社会的な勢力を想起させる、又は、性的な表現に該当し公序良俗に反する恐れがあるもの<br />
        ・第三者の知的財産権を侵害する可能性があるもの<br />
        ・その他、上記各号に準じるもの<br /><br />
        ユーザー名とログインパスワードは、当該登録ユーザー個人に属するものであり、登録時点とは異なる個人又は法人その他の団体に譲渡又は貸与することを禁止します。
      </p>
      <p>
        登録ユーザーは、ユーザー名やログインパスワードが第三者に漏洩した場合、第三者に利用された場合又はこれらの恐れがある場合は直ちに当社に連絡するものとします。なお、登録ユーザーの作為又は不作為を原因として不正なユーザー名やログインパスワードが使用された結果発生した損失、損害について、当社では一切責任を負わず、賠償しません。
      </p>
      <p>
        登録ユーザーが外部サービスのID等を利用して本サービスに登録した場合、そのID等の登録、利用については、外部サービスが規定する各規約の定めに従うものとし、当社はその内容について責任を負いません。
      </p>

      <p class="subtitle-1 font-weight-bold main--text lighten-2">
        第三章：遵守事項・禁止事項・資格停止等について
      </p>
      <p>
        第5条（遵守すべき事項・禁止事項）<br />
        登録ユーザーは、本サービスを利用するに際し、以下の要素を遵守するよう努めるものとします。
      </p>
      <p>
        登録ユーザーは、本サービスを利用するに際し、以下のような法律違反行為を行ってはなりません。
      </p>
      <p>
        ・著作権、特許権等の知的財産権を侵害する行為<br />
        ・肖像権、パブリシティ権を侵害する行為 プライバシーを侵害する行為<br />
        ・機密情報、個人情報、その他第三者が保護する情報を違反又は侵害して掲載する行為<br />
        ・名誉毀損行為、侮辱行為、その他第三者の業務妨害となる行為<br />
        ・詐欺行為、無限連鎖講（ネズミ講）を開設・運営する行為、又はこれらの行為を勧誘する行為<br />
        ・不正アクセス行為の防止等に関する法律に違反する行為、電子計算機損壊等業務妨害罪（刑法第234条の2）に該当する行為をはじめ、当社及び他人のコンピューターに対して不正な操作を行う行為<br />
        ・公序良俗に反する内容の情報を開示する行為<br />
        ・その他犯罪に関わる行為又は法令に違反する行為
      </p>
      <p>
        登録ユーザーは、本サービスを利用するに際し、以下のような社会的に不適切な行為を行ってはなりません。
      </p>
      <p>
        ・自分以外の個人や会社、団体を名乗ったり、権限なく特定の会社や団体の名称を使用したり、架空の個人や会社、団体を名乗ったり、事実がないにも関わらず他の人物や会社、団体と業務提携や協力関係があると偽ったりする行為<br />
        ・第三者になりすましてサービスを利用し、又は、情報を改ざんする行為<br />
        ・前各号のほか、これらに類する社会的に不適切な行動と解される行為
      </p>
      <p>
        登録ユーザーは、本サービスを利用するに際し、以下のような本サービス利用上不適切な行為を行ってはなりません。
      </p>
      <p>
        ・他人の個人情報を盗用、収集、蓄積、変更、利用する行為<br />
        ・自分の個人情報や他の登録ユーザーの情報を不正に操作し、変更する行為<br />
        ・本サービス内でのページデザイン変更により、当社が標準的に表示しているヘッダ、フッタ、広告及び著作権表示を当社の許諾なく非公開にする行為<br />
        ・当社の承諾無く本サービスを転用・売却又は再販する行為<br />
        ・その他、本サービス、本規約の規定又は趣旨に反する行為
      </p>
      <p>
        登録ユーザーが、前三項に定める行為をしたと当社が判断した場合には、当社は、事前の予告なく、当社の判断に応じて以下の対応を行うことができます。なお、この場合に登録ユーザーに損害が生じても、当社は一切の賠償をしません。
      </p>
      <p>
        ・当該登録ユーザーの本サービスの利用の停止又はユーザー資格の停止若しくは終了<br />
        ・当社による登録ユーザーの本規約への違反に対する権利の放棄（明示、黙示を問わず）は、その後の他の登録ユーザーの本規約への違反に対する権利の放棄を意味しません。
      </p>
      <p>
        第6条（本ユーザー資格の取消）<br />
        以下の事由が存在する又はその可能性があると当社が判断した場合には、当社は、当該ユーザーによる本サービスの利用の一時中断、又は本ユーザー資格の取消を行うことがあります。
      </p>
      <p>
        ・登録時の情報又は登録後の情報が不正確な場合。<br />
        ・ユーザー名及びログインパスワードを不正に使用した場合。<br />
        ・本サービスで提供する情報を不正に使用した場合。<br />
        ・ユーザー名及びログインパスワードを使用して利用できる当社の他のサービスにおいて、利用を取消された場合。<br />
        ・その他本規約の記載又は趣旨もしくは本サービスの趣旨に反する行為がある場合。<br /><br />
        登録ユーザーは、本ユーザー資格を取消された場合、本アカウントを使用して利用できるサービスについても、利用資格が取消されます。
      </p>

      <p class="subtitle-1 font-weight-bold main--text lighten-2">
        第三章：遵守事項・禁止事項・資格停止等について
      </p>
      <p>
        第7条（本サービスの変更・中断・終了）<br />
        当社は、本サービスの内容を変更する場合には、変更内容を本サービスに関するお知らせもしくは当社の公式ブログ等に掲載する方法により、通知します。
      </p>
      <p>
        当社は、以下の事項に該当する事由が生じた場合、事前に通知することなく、一時的に本サービスを中断することができます。
      </p>
      <p>
        ・当社が予期せぬサーバーシステムの不具合等が発生した場合。<br />
        ・定期又は緊急対応を問わず、本サービスに関わるメンテナンスや修理を行う場合。<br />
        ・天災、火災、停電、不慮の事故、感染症等の疫病の流行等に関連して、本サービスの提供が困難な事態が生じた場合。
      </p>
      <p>その他、前各号に準じる場合。</p>
      <p>
        当社は、本サービスを最低10日間の予告期間をおいて終了することができます。本サービスの終了は、本サービスに関するお知らせもしくは当社の公式ブログ等に掲載する方法で告知します。
      </p>
      <p>
        前三項による本サービスの変更、中断、終了に関連して登録ユーザーに生じた損害について当社は一切責任を負わないものとします。
      </p>
      <p>
        第8条（ユーザーによる本サービスの終了）<br />
        登録ユーザーは、本サービスの利用を自己の判断に基づき終了することができます。登録ユーザーは、当社の定める退会手続きに従い当社にその旨を通知するものとします。
      </p>
      <p>
        第9条（免責条項）<br />
        登録ユーザーは、本サービスを利用するにあたり必要となるインターネットにアクセスのための機器、ソフトウェア、通信手段を各々の責任と費用において適切に準備、設置、操作するものとします。当社は登録ユーザーのアクセス環境に関与せず、これらの準備、操作に関する責任を負いません。
      </p>
      <p>
        当社は、登録ユーザーに対して連絡を行う場合に、登録されたメールアドレスを宛先とした電子メールで連絡を行うことがあります。登録ユーザーは当社からの電子メールを受信できるよう登録するメールアドレス情報を正しく維持するものとし、何らかの理由で受信ができなくなった場合には、速やかにメールアドレスの変更を行うものとします。メールアドレスの変更又は設定ができていなかったことにより、当社からの連絡を受信できなかったために登録ユーザーが不利益を被った場合、当社は責任を負いません。
      </p>
      <p>
        本サービス上において登録ユーザー自身が自ら開示した、テキスト、コード、スニペット、音声、動画、画像及び画像に付随する情報を含む全ての情報に関する責任は、当該情報を開示した登録ユーザーにあります。当社は登録ユーザーが本サービスにおいて開示した情報の内容について、一切の責任を負いません。
      </p>
      <p>
        登録ユーザーが他人の名誉を毀損した場合、プライバシー権を侵害した場合、著作権法に違反する行為を行った場合、又はその他の方法により他人の権利を侵害した場合、当該登録ユーザーは自身の責任と費用において解決するものとし、当社は一切の責任を負いません。
      </p>
      <p>
        登録ユーザーが開示した情報が原因となって迷惑を受けたとする第三者が現れた場合には、当該登録ユーザーは自身の責任と費用において解決するものとし、当社は一切の責任を負いません。
      </p>
      <p>
        第10条（不保証）<br />
        当社は、以下の内容について一切の保証を行うものではありません。
      </p>
      <p>
        ・本サービスが、登録ユーザーの利用環境に適応し、適正に動作すること<br />
        ・本サービスのサービス内容が登録ユーザーの要求に合致すること又は有益であること<br />
        ・本サービスにおいて当社又が提供する情報が正確、適正、妥当、最新、適法又は完全であること<br />
        ・本サービスの提供が即時であり又は安全であること<br />
        ・本サービスにいかなる瑕疵もないこと<br /><br />
        登録ユーザーは自らの責任において本サービスを利用するものとし、本サービスの利用に関連してなされた一切の行為及びその結果について、登録ユーザーが責任を負うものとします。
      </p>

      <p class="subtitle-1 font-weight-bold main--text lighten-2">
        第五章：一般条項
      </p>
      <p>
        第11条（個人情報の取扱い）<br />
        当社は、登録ユーザーの個人情報を、別途定めるプライバシーポリシーに従い取り扱います。
      </p>
      <p>
        第12条（本サービス提供に関わる知的財産権）<br />
        本サービス及び本サービスを提供するために当社が使用するサーバー等を構成するすべてのプログラム、ソフトウェア、商標、商号又はこれらに付随する知的財産権及びその他の権利は、特段の表示なき限り、当社又は当社に使用を許諾した正当な権限を有する第三者に帰属します。
      </p>
      <p>
        登録ユーザーは、当社又は第三者の知的財産権を、事前かつ明示の許諾なく複製、出版、放送、公衆送信、その他方法を問わず、私的使用の範囲を越えて使用することはできません。
      </p>
      <p></p>
      <p>
        第13条（反社条項）<br />
        登録ユーザーは、自らが暴力団をはじめとする反社会的勢力に該当せず、かつ反社会的勢力との間に資本関係または取引関係その他一切の関係を持っておらず、今後も持たないものであることを保証するものとします。
      </p>
      <p>
        登録ユーザーが、前項の保証に違反したことにより当社との間で紛争が生じたときは、違反した登録ユーザー自らの費用および責任において解決するものとし、これにより、当社に損害が生じた場合、違反した登録ユーザーはその一切を賠償するものとします。
      </p>
      <p>
        第14条（権利義務の譲渡）<br />
        当社は、本サービスに関する営業を第三者に譲渡する場合、最低10日間の予告期間をおいて、事前に本サービスに関するお知らせもしくは当社の公式ブログ等で告知の上、本規約に基づくすべての当社の権利及び義務を、承継、売却、合併、その他の方法で譲渡することができるものとします。当社はこの場合において、当社がかかる権利及び義務を譲り受ける者に登録ユーザーの情報等を、当該譲渡先に開示をすることができるものとします。
      </p>
      <p>
        第15条（分離可能性）<br />
        本規約のいずれかの条項又は一部が、法令等により無効又は執行不能と判断された場合は、当該条項又は一部のみが無効となり、本規約のその他の条項は引き続き完全に効力を有するものとします。
      </p>
      <p>
        第16条（準拠法及び管轄裁判所）<br />
        本規約の解釈及び適用は、日本法に準拠して解釈するものとします。
      </p>
      <p>
        本規約により解決できない問題が生じた場合には、登録ユーザーと当社は、双方誠意をもって話し合い、これを解決するものとします。
      </p>
      <p>
        本規約から生じる又は関連するいかなる訴訟又は訴訟手続きについても東京地方裁判所を第1審の専属管轄裁判所とします。
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "TermsAndConditionsPage",
  data() {
    return {
      lastUpdateDate: dayjs().format(" YYYY年 MM月 DD日")
    };
  }
};
</script>

<style scoped></style>
